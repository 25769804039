<template>
  <v-card id="cardBars" class="my-4 pa-4">
    <v-card-title id="cardTitle">Visão geral por situação</v-card-title>
    <div class="wrapper" id="wrapper">
      <apexCharts class="apexchart" height="100%" :options="chartOptions" :series="counters" type="pie" />
    </div>
  </v-card>
</template>

<script>
let primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--v-primary-base');
import { mapGetters } from 'vuex';

import 'dayjs/locale/pt-br';

export default {
  name: 'SituationOverview',

  components: {
    ApexCharts: () => import('vue-apexcharts'),
    NoData: () => import('../../indicadores/components/NoData.vue'),
  },

  data() {
    return {
      chartOptions: {
        chart: {
          height: '100%',
        },

        tooltip: {
          fillSeriesColor: false,
          followCursor: false,
          style: {
            fontSize: '12px',
            fontFamily: 'Roboto, sans-serif',
            backgroundColor: '#52526B',
            paddingLeft: '8px !important',
            paddingRight: '8px !important',
          },
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let total = 0;
            for (let x of series) {
              total += x;
            }
            let selected = series[seriesIndex];
            return w.config.labels[seriesIndex] + ' (' + ((selected / total) * 100).toFixed(2) + '%)';
          },
        },

        noData: {
          text: undefined,
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#ECE7F5',
            fontSize: '14px',
            fontFamily: undefined,
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.6,
          },
        },
        labels: ['Pendências', 'Conciliadas'],
        legend: {
          position: 'bottom',

          formatter: function (seriesName, opts) {
            return [seriesName, ' (', opts.w.globals.series[opts.seriesIndex], ')'];
          },

          showForSingleSeries: false,
          showForZeroSeries: true,
        },
        colors: [primaryColor, '#00FF99'],
      },
    };
  },

  computed: {
    ...mapGetters({
      counters: 'esocial/esocialMain/getSituationOverview',
    }),
  },
};
</script>
<style scoped>
#cardBars {
  height: 450px;
  border: 1px solid #e7e7fa !important;
  background: #f9fafb !important;
  border-radius: 4px !important;
}
#cardTitle {
  color: var(--v-titleColor-base) !important;
  font-size: 20px;
  font-weight: 'regular';
  margin-bottom: 15px;
}
.wrapper {
  min-height: 300px !important;

  height: 300px !important;
}

::v-deep div.apexcharts-legend {
  inset: auto 100px 0px !important;
  justify-content: unset !important;
  width: 200px !important;
}

::v-deep #SvgjsSvg3244 > foreignObject:nth-child(1) {
  height: 300px !important;
}
</style>
