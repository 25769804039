var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-4 pa-4", attrs: { id: "cardBars" } },
    [
      _c("v-card-title", { attrs: { id: "cardTitle" } }, [
        _vm._v("Visão geral por situação"),
      ]),
      _c(
        "div",
        { staticClass: "wrapper", attrs: { id: "wrapper" } },
        [
          _c("apexCharts", {
            staticClass: "apexchart",
            attrs: {
              height: "100%",
              options: _vm.chartOptions,
              series: _vm.counters,
              type: "pie",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }